import React from 'react'
import styled from 'styled-components'

function Blank() {
  return (
    <Container>

    </Container>
  )
}

const Container = styled.div`
    width: 100%;
    height: 10vh;
    background: var(--main);

`
export default Blank
