import React from 'react'
import styled from 'styled-components'

function BlackPage() {
  return (
    <Container>
      
    </Container>
  )
}

const Container = styled.div`
    width: 100%;
    height: 11vh;
    background: gray;

`

export default BlackPage
